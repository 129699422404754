import React from "react";
import "./hero.scss";
import { Heading } from "../Heading/Heading";
import classNames from "classnames";
import ImageCustom from "../ImageCustom/ImageCustom";
import FadeDown from "../FadeDown/FadeDown";
import arrowRightIcon from "../../images/icons/arrow-right.svg";

export const Hero = ({
  title,
  description,
  btnText,
  btnLink,
  image,
  inner,
  reverse,
  withSpacing,
  homepage,
}) => {
  return (
    <section
      className={classNames("hero", {
        "hero--inner": inner,
        "hero--reverse": reverse,
        "hero--with_spacing": withSpacing,
        "hero--homepage": homepage,
      })}
      role="presentation"
    >
      <div className="hero__text-holder">
        <div className="wrap">
          <FadeDown>
            <Heading level="h1">{title}</Heading>
            <p className="description">{description}</p>
            {btnLink && (
              <a href={btnLink} className="btn btn--primary btn--with_icon">
                <span className="btn__text">{btnText}</span>
                <img src={arrowRightIcon} alt="icon" className="btn__icon" />
              </a>
            )}
          </FadeDown>
        </div>
      </div>
      <div className="hero__image">
        <ImageCustom src={image}></ImageCustom>
      </div>
    </section>
  );
};
