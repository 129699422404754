import React from "react";
import classNames from "classnames";

export const Container = ({ inner, fullWidth, wide, children }) => {
  return (
    <>
      <div
        className={classNames("container", {
          "container--inner": inner,
          "container--full_width": fullWidth,
          "container--wide": wide,
        })}
      >
        {children}
      </div>
    </>
  );
};
