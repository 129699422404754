import React, { Fragment } from "react";
import Layout from "../components/layout/layout";
import { graphql} from "gatsby";
import { Hero } from "../components/Hero/Hero";
import { Card } from "../components/Card/Card";
import { Container } from "../components/Container/Container";
import { Column } from "../components/Column/Column";
import { useTranslation } from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Investments = () => {
  const { t } = useTranslation();
  return (
    <Layout
      pageTitle={t("investments.hero.title")}
      description={t("investments.hero.description")}
    >
      <Hero
        title={t("investments.hero.title")}
        description={t("investments.hero.description")}
        image="investments/investments-hero.jpg"
        btnLink="/contact/"
        btnText={t("global.contact-us")}
        withSpacing
      />
      <Container>
        <Column
          columns_mobile="12"
          columns_tablet="12"
          columns_desktop="12"
          custom_class="no-gutter@xxlg"
        >
          <Card
            title={t("investments.our-history.title")}
            description={
              <Fragment>
                <p>
                {t("investments.our-history.text1")}
                </p>
                <p>
                {t("investments.our-history.text2")}
                </p>
                <p>
                {t("investments.our-history.text3")}
                </p>
              </Fragment>
            }
            image="investments/our-portfolio.jpg"
          />

          <Card
            title={t("investments.future-of-finance.title")}
            description={
              <Fragment>
                <p>
                {t("investments.future-of-finance.text1")}
                </p>
                <p>
                {t("investments.future-of-finance.text2")}
                </p>
                <p>
                {t("investments.future-of-finance.text3")}
                </p>
                <p>
                {t("investments.future-of-finance.text4")}
                </p>
              </Fragment>
            }
            image="investments/future-of-finance.jpg"
            reverse
          />
          <Card
            withSubtitle
            title={t("investments.wintrado-luzern.title")}
            subtitle={t("investments.wintrado-luzern.location")}
            description={
              <Fragment>
                <p>
                {t("investments.wintrado-luzern.text1")}
                </p>
                <p>
                {t("investments.wintrado-luzern.text2")}
                </p>
              </Fragment>
            }
            image="investments/wintrado-technologies.jpg"
          />
          <Card
            reverse
            withSubtitle
            title={t("investments.wintrado-cyprus.title")}
            subtitle={t("investments.wintrado-cyprus.location")}
            description={
              <Fragment>
                <p>
                {t("investments.wintrado-cyprus.text1")}
                </p>
                <p>
                {t("investments.wintrado-cyprus.text2")}
                </p>
                <p>
                {t("investments.wintrado-cyprus.text3")}
                </p>
              </Fragment>
            }
            image="investments/wintrado-cyprus.jpg"
          />
          <Card
            withSubtitle
            title={t("investments.wintrado-international.title")}
            subtitle={t("investments.wintrado-international.location")}
            description={t("investments.wintrado-international.text1")}
            image="investments/wintrado-international.jpg"
          />
        </Column>
      </Container>
    </Layout>
  );
};

export default Investments;
