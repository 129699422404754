import React from "react";
import "./card.scss";
import { Heading } from "../Heading/Heading";
import classNames from "classnames";
import ImageCustom from "../ImageCustom/ImageCustom";
import FadeDown from '../FadeDown/FadeDown';

export const Card = ({ title, description, image, reverse, withSubtitle, subtitle, valueCard }) => {

  return (
    <FadeDown>
      <div
        className={classNames("card", {
          "card--reverse": reverse,
          "card--with-subtitle": withSubtitle,
          "card--value": valueCard,
        })}
      >
        <div className="card__holder">
          <div className="card__image">
            <ImageCustom src={image}  alt={title} className="rspimg project-img"></ImageCustom>
          </div>
          <div className="card__text">
            <Heading card level="h2">{title}</Heading>
            {withSubtitle &&
              <p className="subtitle">{subtitle}</p>
            }
            <div className="description">{description}</div>
          </div>
        </div>
      </div>
    </FadeDown>
  );
};
