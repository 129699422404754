import React from 'react';
import classNames from 'classnames';

export const Column = ({ columns_mobile, columns_tablet, columns_desktop, children, nogutter, custom_class }) => {

    return (
        <>
            <div
                className={classNames(
                    `${custom_class}`,
                    `gr-${columns_mobile}`,
                    `gr-${columns_tablet}@md`,
                    `gr-${columns_desktop}@lg`,
                    {
                        'no-gutter': nogutter
                    }
                )}>
                {children}
            </div>
        </>
    );
};